import gql from 'graphql-tag';

const aniosFiscalesTableQuery = gql`
query anios_fiscales($whereConditions:AniosFiscalesWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!) {
    anios_fiscales(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: DESC}]) {
        data{
            id
            cliente_id,
            empresa_id,
            anio_id,
            descripcion,
            fecha_inicio,
            fecha_final,
            fecha_cierre,
            actual,
            cerrado,
            anio{
                id,
                nombre
            }
        }  
        paginatorInfo{
            currentPage
            lastItem
            hasMorePages
            lastPage
        }
    }
}
`

const queries = { aniosFiscalesTableQuery };

export default queries;