import gql from 'graphql-tag';

const periodosTableQuery = gql`
    query periodos($whereConditions:PeriodosWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder! ) {
        periodos(where:$whereConditions,first: $numberItems, page: $numberPage,  activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                tipo_nomina_id,
                periodicidad_id,
                tipo_nomina_SAT_id,
                numero,
                fecha_inicial,
                fecha_final,
                dias_de_pago,
                especial,
                inicio_de_anio,
                fin_de_anio,
                inicio_de_bimestre,
                fin_de_bimestre,
                inicio_de_mes,
                fin_de_mes,
                mes_de_acomulacion,
                descripcion,
                fecha_de_pago,
                numero_de_factura,
                acumulado,
                cerrado,
                actual,
                tipo_periodo_especial,
                descontar_faltas,
                descontar_incapacidades,
                getEstatus,
                aplicar_tabla_diaria,
                agregar_sueldos

            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const periodosQuery = gql`
    query periodos($whereConditions:PeriodosWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String! ) {
        periodos(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: ASC}]) {
            data {
                id,
                numero,
                descripcion,
                actual
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const periodoActualQuery = gql`
    query periodos($whereConditions:PeriodosWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String! ) {
        periodos(where:$whereConditions, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: ASC}]) {
            data {
                id,
                numero,
                descripcion,
                actual,
                fecha_de_pago
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { periodosTableQuery, periodosQuery, periodoActualQuery };

export default queries;
